@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=block');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=block');

@font-face {
  font-family: Varino;
  src: url("../public/fonts/Varino - Normal.otf") format("opentype");
  font-display: block;
}

.photo {
  height: 1000px;
  width: 1500px;
  background-image: url("../public/photos/mountains-3959204.jpg");
  background-size: cover;
  position: absolute;
  z-index: 1;
  top: -100px;
}

@media (max-width : 1150px) {
  .photo {
    left: -200px;
  }
  .frame {
    left: -300px;
  }

}
@media (max-width : 720px) {
  .photo {
    left: -300px;
  }
  .frame {
    left: -400px;
  }

}
@media (max-width : 600px) {
  .photo {
    left: -500px;
  }
  .frame {
    left: -600px;
  }

}
@media (max-width : 500px) {
  .photo {
    left: -600px;
  }
  .frame {
    left: -700px;
  }

}




.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fontLogo {
  margin-top: 20px;
  font-family: Varino;
  /* color: #C473D6; */
  /* color: #b187cd; */
  color: #c473d6;

;
  font-size: 70px;
  text-align: center;
  width: 100%;
  position: relative;
  right: 6px;
  top: 17px;
}

@media (max-width : 600px) {
  .fontLogo {
    font-size: 50px;
  }

}

@media only screen and (max-width: 700px) {
  .neuronsBackground {
    left: -900px;
  }
}

.fontLogoImage {
  width: 520px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 10px;
  /* top: 10px; */
}
.navbar {
  min-height: 100px;
  /* z-index: 30; */
  /* border-color: rgb(233, 233, 233); */
  /* border-style: solid;  */
  /* border-width: 1px; */
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;

}
.nav-text{
  /* color: #C473D6; */
  /* background-color: #da4597; */
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  padding: 10px;
  position: relative;
  /* top: 6px; */
}
.dropdown-menu {
  top: 90px !important;
  background-color:  #c473d6 !important;
  border-color: white;
  padding: 0px !important;
  margin: 0px !important;
  border: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;


}

.nav-text-disabled {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  padding: 10px;
}
.dropdown-item:hover {
  background-color: #954f9c !important
}
.dropdown-item {
  height: 100% !important
}
.dropdown-divider {
  margin: 0px !important
}

.bg-myBlue{
  /* background-color: #da4597; */
  /* background-color: #EB33FF; */
  /* background-color:#E564FF; */
  /* background-color: #E05DEE; */
  background-color: #c473d6

}

.content{
  padding: 12px;
  height: 3000px;
  background-color: lightblue;
}
.application{
  background-color: white;
}

#collapsible-nav-dropdown{
  color: white;
}



.emdr {
  /* min-height: 100vh; */
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.start {
  display: flex;
  justify-content: center;
  align-items: center;
}

.startButton {
  padding: 15px;
  padding-bottom: 20px;
}

h1 {
  text-align: center;
  color: darkslategray;
  padding: 20px;
  margin-left: 5%;
  margin-right: 5%;
}
h2 {
  text-align: center;
  color:darkslategray;
  padding: 20px;
  margin-left: 5%;
  margin-right: 5%;
}
h3 {
  font-size: 25px;
  font-weight: normal;
  text-align: left;
  color: darkslategray;
  padding: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

.stage0 {
  padding: 25px;
}

@media (max-height : 600px) {
  .homeText {
    position: relative;
    top: -23px;
  }
  .tryButton {
    position: relative;
    top: -19px;
  }

}


@media (max-width: 800px) {
  .cards {
    flex-direction: column;
    /* max-width: 380px; */
    margin: auto;
  }

}

/* img {
  width: 300px;
  height: auto;
} */

.navLogo {
  width: 70px;
  height: auto;
  position: relative;
}
.navbar-collapse {
  color: white;
}

.textBox {
  padding: 20px;
  margin-left: 5%;
  margin-right: 5%;
}
.targetContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-left: 5%;
  margin-right: 5%;
}
.target {
  display: flex;
  font-size: 30px;
  text-align: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 5%;
  margin-right: 5%;
  color: white;
  background-color: gray;
  border-radius: 25px;
  overflow-wrap: anywhere;
  max-width: 90%;
}

.numberSelect {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-primary {
  z-index: 1 !important;
}
.btn-secondary {
  background: gray !important;
  border-color: gray !important;
  z-index: 0 !important;
}

.Btn-Blue-BG:select {
  background-color: #0d6efd !important;

}
.emdrBackground {
  background-color: white;
  position:absolute;
  left: 0;
  top: 98px;
  width:100%;
  height:100%;
  z-index:20;
}
.dot {
  background-color: blue;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: fixed;
  top: 40%;
  z-index: 10;
  animation-duration: .8s;
  animation-name: track;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}
@keyframes track {
  from {
    left: 0%;
  }
  to {
    left: 96%;
  }
}

.breathContainer {
  height: 200px;
  width: 200px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  align-items: center;
  justify-content: center;
}
.breathDot {
  background-color: blue;
  border-radius: 50%;

  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  animation-duration: 19s;
  animation-name: breathe;
  animation-iteration-count: 4;
  animation-timing-function: linear;
}
.breathText {
  color: white;
  font-size: 27px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  width: 100%;
 -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  vertical-align: center;
}
@keyframes breathe {
  0% {
    height: 100px;
    width: 100px;
    margin-left: auto; 
    margin-right: auto; 
  }
  21.05% {
    height: 200px;
    width: 200px;
    margin-left: auto; 
    margin-right: auto; 
  }
  57.89% {
    height: 200px;
    width: 200px;
    margin-left: auto; 
    margin-right: auto; 
  }
  100% {
    height: 100px;
    width: 100px;
    margin-left: auto; 
    margin-right: auto; 
  }
}

@keyframes flash {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}

.flash {
  animation: flash;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

.resultsTable {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.agreeForm {
  justify-content: center;
}
.termsInput {
  text-align: center;
  display: flex; 
  align-items: center;
}
.formContainer {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width : 400px) {
  .formContainer {
    max-width: 250px;
  }
}

.modalFooter {
  display: flex;
}
.contact {
  min-height: 100vh;
  padding: 20px;
  margin-left: 15%;
  margin-right: 15%;
}
.contactFormElement {
  padding: 10px;
}
.contactSubmitButton {
  margin: 10px;
  padding: 10px;
}

.listInput {
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 10px !important;
  width: 70% !important
}
.formLabel {
  text-align: right;
}
.controlList {
  text-align: center;
  justify-content: center;
}
.controlItem {
  font-size: 30px;
  text-align: left;
  width: 400px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  background-color: gray;
  border-radius: 25px;
  overflow-wrap: anywhere;
  max-width: 90%;
}

.controlItemIcon {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.controlItemIcon:hover {
  color: darkgray;
}
.controlItemIcon:active {
  color: #dc3545;
}

.controlItemIconGreen {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.controlItemIconGreen:hover {
  color: #6c757d;;
}
.controlItemIconGreen:active {
  /* color: #198754; */
  color: black;
}

.dashboardView {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
 
  width: 100%;
  align-items: center;
  overflow: scroll;
}

/* parallax... */

.wrapper {
  height: calc(100vh - 100px);
  /* max-height: 300vh; */
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
}

.home {
  position: relative;
  bottom: 420vh;
  z-index: -1;
  height: 200px;
}


header {
  position: relative;
  display: flex;
  /* width: 100%; */
  height: 100%;
  /* justify-content: center; */
  /* align-items: center; */
  transform-style: preserve-3d;
  z-index: -1;
  /* top: 700px; */

}

.background {
  background-color: ;
  /* background-image: url('../public/neurons5_background.svg'); */
  transform: translateZ(-10px) scale(2);
}

.foreground {
  /* background-image: url('../public/neurons5_foreground.svg'); */
  transform: translateZ(-5px) scale(1.5);
}

.background,
.foreground {
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  /* width: 1300px; */
  width: 100vh;
  height: 2000px;
  /* object-fit: cover; /
  /* margin: 0; */
  /* top: -700px; */
  
  z-index: -1;

  /* overflow-y: hidden; */
}

.parallaxBackground,
.parallaxForeground {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.title {
  font-size: 7rem;
  color: pink;
  text-shadow: 0 0 5px black;
}

section {
  font-size: 2rem;
  padding: 2rem;
  margin: 2rem;
  background-color: #333;
  color: white;
}
