*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.dashboard{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 100px);}

.page{
	flex-basis: 100%;
	display: flex;
	justify-content: center;
	height: calc(100vh - 100px);
	/* align-items: center; */
	transition: margin-left 1s;
}

.page-with-navbar{
	margin-left: 200px;
	height: calc(100vh - 100px);
}

@media only screen and (max-width: 768px){
	.page-with-navbar{
		width: 100%;
		margin-left: 0;
	}

}

#side-drawer{
	position:absolute;
	/* top:0; */
	left:0;
	display:flex;
	flex-direction:column;
	justify-content: space-between;
	width: 200px;
	height: calc(100vh - 100px);
	/* min-height: 100vh; */
	background-color: #c473d6;
	padding:2rem 1rem 1rem 1rem;
	transition: transform 1s;
	z-index: 10;
}

.sidebar{
	transform: translateX(-100%);
}

.side-btn{
	position: absolute;
	transform: translateX(38px);
	top: 0px;
	right: 0;
	width:40px;
	height: 80px;
	/* border-top-right-radius: 5px; */
	border-bottom-right-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #c473d6;
	outline: none;
	border: none;
	font-size: 1rem;
	padding: 5px 10px;
	cursor: pointer;
	color: white;
}

.side-link{
	display: flex;
	align-items: center;
	color: white;
	text-decoration: none;
	padding: 10px 1rem;
	border-radius: 5px;
	margin-bottom: 5px;
	cursor: pointer;
}

.side-link:hover{
	background-color:#AFB4FF;
	color: #4e4e4e;
}

.side-link span{
	margin-left: 10px;
}

/* .side-top{ */
	/* margin-top: 90px; */
/* } */

.mobile-side{
	width: 100%;
	height:40px;
	display: none;
}

.mobile-side-btn{
	color: #fff;
	background: transparent;
	outline: none;
	border: none;
	margin: 0 10px;
}

@media only screen and (max-width: 768px){
	side{
		width: 40%;
		padding: 10px;
	}
}

